import React from 'react';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import PeopleList from "./Components/PeopleList"
import Dashboard from "./Components/Dashboard"
import Home from "./Components/Home"
import AboutUs from "./Components/AboutUs"
import Terms from "./Components/Terms"
import Privacy from "./Components/Privacy"
import Copyright from "./Components/Copyright"
import Faq from "./Components/Faq"


export default function Routes() {
    return <Switch>
        <Route exact path={`/`} component={Home} />
        <Route exact path={`/about`} component={AboutUs} />
        <Route exact path={`/terms`} component={Terms} />
        <Route exact path={`/privacy`} component={Privacy} />
        <Route exact path={`/copyright`} component={Copyright} />
        <Route exact path={`/faq`} component={Faq} />
        <Route path={`/track/:trackingId`} component={Dashboard} />
    </Switch>
}