
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { ArrowCircleRightIcon, CheckCircleIcon, ThumbUpIcon } from '@heroicons/react/solid'

import { useParams } from "react-router-dom";
import { Tracking } from '../Models/Tracking';



function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [trackingData, setTrackingData] = useState<Tracking | undefined>();
    let { trackingId } = useParams<{ trackingId: string }>();

    useEffect(() => {
        (async () => {
            if (trackingId) {
                const res = await fetch(`/api/track/${trackingId}`)
                const data: Tracking = await res.json()
                setTrackingData(data)
                setLoading(false)
                console.log(data)
            }
        })()
    }, [trackingId])

    return (
        <>
            <div className=" px-4 space-y-2 sm:px-0 sm:items-baseline sm:justify-between sm:space-y-0">
                <div className="sm:items-baseline sm:space-x-4">
                    <h1 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Your shipment: {trackingId}</h1>
                </div>
                {trackingData?.data?.items[0].lastUpdateTime &&
                    <p className=" text-sm text-gray-600">
                        Last update {' '}
                        <time dateTime="2021-03-22" className="font-medium text-gray-900">
                            {new Date(trackingData?.data?.items[0].lastUpdateTime).toLocaleDateString('en-US')} {new Date(trackingData?.data?.items[0].lastUpdateTime).toLocaleTimeString('en-US')}
                        </time>
                    </p>
                }
                <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:hidden">
                    View invoice<span aria-hidden="true"> &rarr;</span>
                </a>
            </div>
            {/* Products */}
            <section aria-labelledby="products-heading" className="mt-6">
                {
                    loading ?
                        <div className="flex items-center justify-center ">
                            <div className="w-40 h-40 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
                        </div>
                        :
                        <>
                            {
                                trackingData?.data?.items[0] &&
                                <div className="flow-root w-1/3">
                                    {
                                        trackingData?.data?.items[0]?.origin_info?.trackinfo ?
                                            <ul role="list" className="-mb-8">
                                                {trackingData?.data?.items[0]?.origin_info?.trackinfo?.map((event, eventIdx, arr) => (
                                                    <li key={eventIdx}>
                                                        <div className="relative pb-8">
                                                            {eventIdx !== arr.length - 1 ? (
                                                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                            ) : null}
                                                            <div className="relative flex space-x-3">
                                                                <div>
                                                                    <span
                                                                        className={`${eventIdx == 0 ? 'bg-blue-500' : 'bg-blue-300'} h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white`}
                                                                    >
                                                                        {eventIdx == 0 ? <ArrowCircleRightIcon className="h-5 w-5 text-white" aria-hidden="true" /> : <CheckCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                                                                    </span>
                                                                </div>
                                                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                                    <div>
                                                                        <p className="text-sm text-gray-500">
                                                                            {event.StatusDescription}{' '}
                                                                            <a href="#" className="font-medium text-gray-900">
                                                                                {event.Details}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                                        <time dateTime={event.Date}>
                                                                            {new Date(event.Date).toLocaleDateString('en-US')} {new Date(event.Date).toLocaleTimeString('en-US')}
                                                                        </time>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            :
                                            <div className="flow-root">
                                                <h3>Shipping Label Created</h3>
                                                <p>Your package is on its way to a facility.</p>
                                            </div>
                                    }

                                </div>
                            }

                            {
                                trackingData?.status === 'not_found' &&
                                <div className="flow-root w-1/3">
                                    Tracking not found
                                </div>
                            }

                            {
                                trackingData?.status === 'pending' &&
                                <div className="flow-root w-1/3">
                                    Shipping Label Created
                                    <p>Your package is on its way to a facility.</p>
                                </div>
                            }
                        </>


                }


            </section>

        </>
    )
}
