import { BrowserRouter as Router, Route, Switch, Redirect,Link } from "react-router-dom";
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon } from '@heroicons/react/solid'

import { useParams } from "react-router-dom";

import './App.css';
import Routes from "./Routes";

const navigation = {
    main: [
      { name: 'About', href: '/about' },
      { name: 'Terms', href: '/terms' },
      { name: 'Privacy', href: '/privacy' },
      { name: 'Copyright', href: '/copyright' },
      { name: 'FAQ', href: '/faq' },
    ]
  }


function App() {
    const [open, setOpen] = useState(false)
    return (
        <div className="bg-gray-50">
            <Router>
            {/* Mobile menu */}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                            <div className="px-4 pt-5 pb-2 flex">
                                <button
                                    type="button"
                                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>

                            {/* Links */}




                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                <div className="flow-root">
                                    <a href="#" className="-m-2 p-2 block font-medium text-gray-900">
                                        Create an account
                                    </a>
                                </div>
                                <div className="flow-root">
                                    <a href="#" className="-m-2 p-2 block font-medium text-gray-900">
                                        Sign in
                                    </a>
                                </div>
                            </div>

                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                {/* Currency selector */}
                                <form>
                                    <div className="inline-block">
                                        <label htmlFor="mobile-currency" className="sr-only">
                                            Currency
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

            <header className="relative">
                <nav aria-label="Top">
                    {/* Top navigation */}
                    <div className="bg-gray-900">
                        <div className="max-w-7xl mx-auto h-10 px-4 flex items-center justify-between sm:px-6 lg:px-8">
                            <p className="flex-1 text-center text-sm font-medium text-white lg:flex-none">
                            </p>
                            <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                            </div>
                        </div>
                    </div>

                    {/* Secondary navigation */}
                    <div className="bg-white border-b border-gray-200">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="h-16 flex items-center justify-between">
                                {/* Logo (lg+) */}
                                <div className="hidden lg:flex lg:items-center">
                                    <a href="/">
                                        <span className="sr-only">Workflow</span>
                                        <img
                                            className="h-16 w-auto"
                                            src="/logo_transparent.png"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                {/* Logo (lg-) */}
                                <a href="/" className="lg:hidden">
                                    <span className="sr-only">Workflow</span>
                                    <img
                                        src="/logo_transparent.png"
                                        alt=""
                                        className="h-16 w-auto"
                                    />
                                </a>

                                <div className="flex-1 flex items-center justify-end">
                                    <div className="flex items-center lg:ml-8">
                                        <div className="flex space-x-8">

                                        </div>

                                        <span className="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true" />

                                        <div className="flow-root">
                                            <a href="#" className="group -m-2 p-2 flex items-center">

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <main className="px-4 max-w-2xl mb-10 mx-auto pt-8 pb-24 sm:pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                    <Routes />
            </main>

            <footer className="bg-white mt-20">
                <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        {navigation.main.map((item) => (
                            <div key={item.name} className="px-5 py-2">
                                <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                    </nav>
                    
                    <p className="mt-8 text-center text-base text-gray-400">&copy;  2022 AllInOneTrack, Ltd.</p>
                </div>
            </footer>
            </Router>
        </div>


    )
}

export default App;
