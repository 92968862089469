import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
  {
    question: "What's the definition of each package status?",
    answer:
      <>
        <p className='mt-5'>It indicates the current package status during transportation. You may refer to our status definition: Not Found / In Transit / Pick Up / Undelivered / Delivered / Alert / Expired. When tracking is completed, we will show the status according to all detailed tracking information.</p>
        <p className='mt-5'>Given that the package status is auto-judged by the system, it's only for information reference use. Kindly note AllInOneTrack website/apps works as an interface with the actual carriers. It is the info they enter into their database that we can get and present to you. But we are not responsible in any way for your shipment. In any instance, if the system gives you an incorrect package status, please don't hesitate to contact us.</p>
        <h3 className='mt-7 text-lg underline'>Not Found</h3>
        <p className='mt-5 border-l-4 pl-5'>Item is not found at this moment, if necessary, please verify with the package sender and check back later.</p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>The carrier hasn't accepted your package yet.</li>
          <li>The carrier hasn't scanned and entered tracking information for your package yet.</li>
          <li>Your tracking number is incorrect or invalid.</li>
          <li>The item was posted a long time ago, info not available anymore.</li>
        </ul>
        <p className='mt-5'>
          Generally, after the sender ships your package, it will be processed by the carrier, then they scan and enter the tracking information. There might be a delay between these scanning events and tracking availability in their system. Usually, it takes a few days for processing, therefore the tracking information may not appear online immediately, please try to track again later.
        </p>

        <h3 className='mt-7 text-lg underline'>In Transit</h3>
        <p className='mt-5 border-l-4 pl-5'>Item has shipped from originating country and is en route to its destination.</p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>Your package has been handed over to the carrier.</li>
          <li>Your package has been dispatched or departed from its country of origin.</li>
          <li>Your package has arrived its destination country and pending customs inspection.</li>
          <li>Your package has arrived its destination country and during domestic transportation.</li>
          <li>Your package is under another transportation period. For instance, it's in another country as a transit point and will be forwarded from there to its final destination country.</li>
        </ul>
        <p className='mt-5'>Please pay attention to detailed tracking information, if your item has arrived its destination country, we'd advise you to track it again within 1-2 days and observe the latest updates to ensure a smooth receipt of the package.</p>

        <h3 className='mt-7 text-lg underline'>Pick Up</h3>
        <p className='mt-5 border-l-4 pl-5'>Item is out for delivery or arrived at local facility, you may schedule for delivery or pickup. Please be aware of the collection deadline.</p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>Your package has arrived at a local delivery point.</li>
          <li>Your package is out for delivery.</li>
        </ul>
        <p className='mt-5'>If you ensure your package has been delivered successfully, please ignore this notice. However, if you haven't received your package, we'd advise you to contact the carrier to arrange a re-delivery or collect your item. Hint: Generally, the carrier has collection deadline, we'd advise you pick up your package at once, or it might be returned to the sender.</p>

        <h3 className='mt-7 text-lg underline'>Undelivered</h3>
        <p className='mt-5 border-l-4 pl-5'>Item was attempted for delivery but failed, this may due to several reasons. Please contact the carrier for clarification. </p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>Possible reasons for unsuccessful item delivery attempt: addressee not available at the time of delivery; delivery delayed and rescheduled, addressee requested later delivery, address problem - unable to locate premises, rural or remote areas, etc.</li>
        </ul>
        <p className='mt-5'>If you ensure your package has been delivered successfully, please ignore this notice. However, if you haven't received your package, we'd advise you to contact the carrier to arrange a re-delivery or collect your item. Hint: Generally, the carrier has collection deadline, we'd advise you pick up your package at once, or it might be returned to the sender.</p>


        <h3 className='mt-7 text-lg underline'>Delivered</h3>
        <p className='mt-5 border-l-4 pl-5'>Item was delivered successfully to the addressee.</p>
        <p className='mt-5'>General explanation:</p>
        <p>Under most circumstances, the delivered status indicates that the carrier has delivered this package successfully. If the addressee didn't receive it, we'd advise you to contact the package sender or shipping carrier for clarification.</p>


        <h3 className='mt-7 text-lg underline'>Alert</h3>
        <p className='mt-5 border-l-4 pl-5'>Item might undergo unusual shipping condition, this may due to several reasons, most likely item was returned to sender, customs issue, lost, damaged etc.</p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>Your package is being returned to sender due to any of these reasons: item refused by addressee; incorrect / illegible / incomplete address; expired retention period; addressee absence; etc.</li>
          <li>Your package might be retained by customs department due to any of these reasons: contains prohibited goods; importation of the goods is restricted; retained by customs due to tax payable, or any other unspecified reasons.</li>
          <li>Your package may have suffered damage or been lost during the transportation period.</li>
        </ul>
        <p className='mt-5'>If you ensure your package has been delivered successfully, please ignore this notice. However, if you haven't received your package and it is under the “Alert" status, then please pay attention: you need to read and analyze the detailed tracking information carefully. Due to complex description of worldwide shipping providers, we can't auto-detect and determine all the status 100% accurately, hope you understand. We'd advise you to contact the package sender or your shipping carrier for clarification.</p>

        <h3 className='mt-7 text-lg underline'>Expired</h3>
        <p className='mt-5 border-l-4 pl-5'>Item was in transportation period for a long time still has no delivered results.</p>
        <p className='mt-5'>General explanation:</p>
        <ul className='list-disc list-inside'>
          <li>At a certain stage during transportation, the carrier has not updated the tracking information due to the high volume of postage.</li>
          <li>The carrier was omitted to enter the tracking information.</li>
          <li>Your package may have been lost during the transportation period.</li>
        </ul>
        <p className='mt-5'>If you ensure your package has been delivered successfully, please ignore this notice. However, if you haven't received your package, please pay attention, we'd advise you to contact the package sender or your shipping carrier for clarification.</p>
      </>,
  },
  {
    question: "How to mail a parcel internationally?",
    answer:
      <>
        <p className='mt-5'>Here are some shipping tips for global mailing.</p>
        <h3 className='mt-7 text-lg underline'>Addressing</h3>
        <p className='mt-5'>Addressing Tips</p>

        <ul className='list-disc list-inside'>
          <li>Full name and address of the recipient.</li>
          <li>If you handwrite the address, USE CAPITALS.</li>
          <li>Ensure the writing is Clear, Legible and Accurate.</li>
          <li>Return Address of the sender.</li>
          <li>If possible, use a printed or typed address instead of a handwritten one.</li>
          <li>Sufficient postage.</li>
          <li>ALWAYS include the Postcode or Zip code.</li>
          <li>The phone number of the recipient. This will help avoid delay, especially with packets, parcels or items with merchandise in the event of an enquiry or payment charges, with registered letters/packets, Parcels or EMS items. Allow space for a bar-coded label (90mm x 70mm) on the front next to the recipient's address.</li>
        </ul>

        <h3 className='mt-7 text-lg underline'>Different Country Addressing Formats</h3>
        <p className='mt-5'>To best assist your parcel to get to its destination you should adopt the preferred addressing format of the country you are sending your letter to.</p>
        <p>For example, some countries have a numerical postcode, others have an alpha-numeric postcode. Some countries place the house number at the start of the street name, whilst others position the number at the end.</p>
        <p>Always check with the recipient for the correct addressing format.</p>

        <h3 className='mt-7 text-lg underline'>Packaging And Wrapping</h3>
        <p className='mt-5'>All postal items need to be packaged sufficiently well to ensure the integrity of the contents being posted. Use packaging material, both internally and externally, that is sufficiently durable and strong to ensure that the item(s) contained inside does not become damaged, or the outer wrapping become perforated during its journey</p>

        <h3 className='mt-7 text-lg underline'>Packaging Tips</h3>
        <p className='mt-5'>Use reinforced or similar carton tape (approx. 50mm wide) to make an 'H' seal on both the top and bottom of your package. Ordinary household adhesive tape isn't usually strong enough.</p>
        <p className='mt-5'>Parcels should use boxes made of strong carton. The heavier the parcel the thicker the carton should be or double-up the carton, e.g., a box within a box.</p>

        <h3 className='mt-7 text-lg underline'>Compensation</h3>
        <p className='mt-5'>Compensation may be available in the event of loss or damage of an item you may have posted. This will depend on the service used and the circumstances. They relate to the levels stipulated by the Universal Postal Union (Letter and Parcel Post Manuals), which all member countries are required to adhere to. In the event of a claim, an application form shall need to be filled. Forms are available from all counters. Before using any service and/or making a claim please be aware of the following:</p>
        <p className='mt-5'>It is essential that the Certificate of Posting or receipt (plus copies of any other documentation) be kept as they will be required to register and process a claim. Claims are normally registered after a period of 1 month. A Claim Form will need to be completed in full and signed by the sender.</p>

        <h3 className='mt-7 text-lg underline'>Shipping Or Delivery Time</h3>
        <p className='mt-5'>The tracking availability of our services depends on if the official carrier supports it or not. AllInOneTrack doesn't have any influence when it comes to shipping or delivery process. From our experience, most postal/registered parcel/articles arrive around 2-4 weeks (slight difference according to different countries/regions, especially remote countries, where they might need to transit through a different country). EMS mail usually arrives around 1-3 weeks. Shipping time is very dependent on mailman delivery process and different country/region customs control. You may refer to our Transport Statistics feature to get an approximate delivery time.</p>

        <h3 className='mt-7 text-lg underline'>Tracking Coverage</h3>
        <p className='mt-5'>AllInOneTrack supports 170 postal carriers and multiple express couriers worldwide. Click here to view all carrier's information and trackable carrier list.</p>

        <h3 className='mt-7 text-lg underline'>Restricted Goods</h3>
        <p className='mt-5'>You are responsible for checking whether or not an item is prohibited or restricted. If you send prohibited goods or restricted goods (and you do not comply with the relevant terms and conditions), the postal carrier may deal with your items as they see fit, including but not limited to, disposing of the parcels concerned (in whole or in part). If you are not sure about the item you wish to post, please double-check with your shipping agency directly.</p>

        <h3 className='mt-7 text-lg underline'>Customs Declaration</h3>
        <p className='mt-5'>If you are sending a package internationally, then you are required to fill up a CN 22 form as customs declaration. Most users of AllInOneTrack.net claim the goods contained in this package are gifts/samples for small pack of postal mail to avoid customs charges. Please contact individual online merchants for more details.</p>

        <h3 className='mt-7 text-lg underline'>Tariff</h3>
        <p className='mt-5'>If you are expecting a package from an oversea country, and if there is a tariff payment charged, you will receive a card saying your package needs to pay a customs charge. Here's what you need to do: Contact as indicated on the notice card or visit the local post office to pay your fee and collect you package with your identification card. If you don't know if a tax payment needs to be charged or not, please visit the Customs department website of your country and figure out.</p>

        <h3 className='mt-7 text-lg underline'>Size / Weight Restrictions</h3>
        <p className='mt-5'>Please double-check with the shipping agent you collaborate with to ensure it is suitable for UPU postal rules so as to facilitate its safe and reliable delivery.</p>
        <p className='mt-5'>Most of AllInOneTrack users send or receive postal packages with the shipping type below:</p>
        <ul className='list-disc list-inside'>
          <li>Postal of registered mail (under 2kgs)</li>
          <li>Postal of parcel mail (over 2kgs)</li>
          <li>EMS package & other express service</li>
          <li>Your package has arrived its destination country and during domestic transportation.</li>
          <li>Prohibited Goods</li>
        </ul>
        <p className='mt-5'>There are some items which postal carriers are unable to carry for legal or health and safety reasons. If you are not sure about the item you wish to post, please double-check with your shipping agency to avoid retention by customs or return to sender.</p>
        <p className='mt-5'>Here is a general list of prohibited items:</p>
        <ul className='list-disc list-inside'>
          <li>Drugs and psychotropic substances.</li>
          <li>Weapons, explosive, flammable, radioactive and other dangerous substances.</li>
          <li>Items with insulting or indecent inscriptions or images.</li>
          <li>Live animals, other than bees, leeches, silkworms, pest, and vermin control products sent in registered small packages to officially recognize institutions.</li>
          <li>Alcoholic beverages of more than 70% alcohol.</li>
          <li>Alcoholic beverages in non-hermetically sealed bottles.</li>
          <li>All types of aerosols.</li>
          <li>Coins, banknotes, cheques or any bearer securities, traveler’s cheques, processed or non-processed platinum, gold or silver, precious stones, jewelry, and other valuable articles can be sent as insured postal items only.</li>
        </ul>
      </>
  }

]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}