
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon } from '@heroicons/react/solid'


export default function Example() {


    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
                        <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                            Who we are
                        </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            About us
                        </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                        AllInOneTrack, Ltd. provides an ALL-IN-ONE package tracking service. The web/apps are translated into multiple language versions, and the users are from around 220 countries and areas. As an indispensable package tracking system for the eCommerce industry, AllInOneTrack has a great impact on millions of online retailers & their customers.
                    </p>
                </div>
            </div>
        </div>
    )
}
