
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon } from '@heroicons/react/solid'


export default function Example() {


    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-md text-justify px-10 mx-auto">
                    <h1>
                        <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                            Last Updated: 8th Aug 2021
                        </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Copyright
                        </span>
                    </h1>
                    <p className="mt-8 text-gray-500 ">
                        <p className='mt-3'>
                            Copyright and complaints
                        </p>
                        <p className='mt-3'>
                            AllInOneTrack.com itself, all the intellectual property of the service itself and all software containing relevant intellectual property as well as any other applications owned by AllInOneTrack, are protected by the RA and other applicable laws. All products provided by AllInOneTrack.com, including information, layout design, graphics, appearance, logo and other parts of the software and website, are owned by AllInOneTrack.com. AllInOneTrack have exclusive copyrights over it and other intellectual property No third party shall, by any means, copy, disseminate, sell, distribute, publish, adapt, translate, derive, process and/or use without license from AllInOneTrack.com.

                        </p>
                        <p className='mt-3'>
                            AllInOneTrack.com respects the intellectual property of others. If you think your copyright and/or other intellectual property rights being infringed, please contact us by email, in order to take prompt and adequate measures.

                        </p>
                        <p className='mt-3'>
                            Registered trademark
                        </p>
                        <p className='mt-3'>
                            Products, services, and logos of AllInOneTrack are all protected by applicable laws, those trademarks, distinguished names, logos cannot be used on any other Third party's products, neither on similar products in a confusing way, nor in a malicious way.

                        </p>
                        <p className='mt-3'>
                            Disclaimer
                        </p>
                        <p className='mt-3'>
                            AllInOneTrack.com provides tracking services, sourcing from official information of a variety of logistic carriers. Our website merely makes representation that the tracking information truly and accurately consistent with information on the official site of logistic carriers, but make no warranty in respect of the rationality, accuracy and integrity. Furthermore, in no event shall our website be responsible for any losses or damages arising from unreasonable, inaccurate or incomplete information.
                        </p>
                        <p className='mt-3'>
                            AllInOneTrack.com makes no warranty in respect of the following listed matters, in any form, either express or implied, including without limitation, providing tracking information without interpretation, timely updates, safe, accurate, and integral. In no circumstances will AllInOneTrack be responsible for damages caused, directly or indirectly, by the information on our website. The contact information of operators provided by AllInOneTrack can only be used for reference, and cannot be used for trading or servicing purposes, and for any deviation arising from arbitrarily using the contact information; our website shall take no responsibility.
                        </p>
                        <p className='mt-3'>
                            Our website shall take no responsibility for any losses caused by force majeure, such as hacker attacks, virus invasion or flaring, temporarily shut down, due to government interference, etc. Our website is not responsible for personal information disclosure and resulting legal disputes and consequences caused by any other sites linked from our website.
                        </p>

                        <p className='mt-3'>
                            For system maintenance or update, we shall post the notice in advance.

                        </p>


                        <p>
                            Our website shall not be responsible for loss or inconvenience caused by a temporary termination of service because of network malfunction, technical faults or other types of force majeure.

                        </p>

                        <p>
                            Users shall be independently responsible for the consequences as a result of your breach hereof and therefore breach the law.

                        </p>
                        <p>
                            Any access to our website, in any manner, directly or indirectly making use of the resources from our website, shall be regarded as you voluntarily accepting the restrictions herein.

                        </p>
                        <p>
                            As to other matters not covered by this statement, please refer to relevant laws and regulations. In the case that This Statement conflicts with laws and regulations, the laws and regulations prevail.

                        </p>
                        <p>
                            Applicable laws

                        </p>

                        <p>
                            The foregoing statement shall be governed by the laws and regulations of the RA. AllInOneTrack reserves the right to amend This Statement from time to time without prior notices.

                        </p>



                    </p>
                </div>
            </div>
        </div>
    )
}
