
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon } from '@heroicons/react/solid'


export default function Example() {


    return (
        <div className="relative py-16 bg-white overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-md text-justify px-10 mx-auto">
                    <h1>
                        <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                            Last Updated: 8th Aug 2021
                        </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Terms
                        </span>
                    </h1>
                    <p className="mt-8 text-gray-500 ">
                        <p className='mt-3'>                        This licensing agreement (hereinafter referred to as 'This Agreement') is made between you and QWERTY LLC (hereinafter called 'AllInOneTrack'). Please carefully read This Agreement before using the software or 'AllInOneTrack' site (hereinafter called 'Licensing Software'). By downloading, installing, or using Licensing Software, you agree that you fully understand and accept all terms and conditions of This Agreement. If you do not accept the terms and conditions of This Agreement, please do not use Licensing Software, and remember to destroy all copies of Licensing Software.
                        </p>
                        <p className='mt-3'>
                            AllInOneTrack reserves the right to amend This Agreement from time to time, and in case of that, we will post a relevant notice on 'AllInOneTrack' official website without any separate notice. Such amendment(s) shall automatically come into force and become effective five days after the notice. By continuing to use Licensing Software after the amendment, you agree that you have read and accepted This Agreement.

                        </p>
                        <p className='mt-3'>
                            Definition
                            Licensing Software: means software system, which was developed by AllInOneTrack, in the form of an application installed on mobile and/or PC devices terminals, or in the form of the website AllInOneTrack.net, accessed through browsers.

                        </p>
                        <p className='mt-3'>
                            AllInOneTrack Service means all services provided by AllInOneTrack (including but not limited to application service). You can access AllInOneTrack Services through the Licensing Software installed on mobile and/or PC terminal devices.

                        </p>
                        <p className='mt-3'>
                            Scope of authority
                            AllInOneTrack grants you a limited, non-exclusive, unalienable, un-sublicensing license. You shall install and/or use on more than one terminal devices for non-commercial purposes. However, you shall neither install, use, operate Licensing Software for business operating purposes, nor could you copy, alter, modify, articulated run or create any derivative works from the data, data released on any terminals or interactive data between customer and server terminals, in multiple forms, including without limitation, getting access to Licensing Software and relevant systems by taking advantages of plugins or any unauthorized third-party instruments/services.

                        </p>
                        <p className='mt-3'>
                            By downloading and installing, and/or initially accessing the Licensing Software, the licensing relationship is set up. You shall comply with the related 'Agreement on Licensing and Limitation' in terminal operating systems. Once AllInOneTrack has reasonable ground to believe that you have breached the agreement, AllInOneTrack is authorized to terminate your license at any time.
                        </p>
                        <p className='mt-3'>
                            Based on This Agreement, AllInOneTrack grants you a license other than selling you Licensing Software. You shall only use Licensing Software subject to This Agreement; AllInOneTrack reserves all rights that are not expressly granted.

                        </p>
                        <p className='mt-3'>
                            Usage specification
                            You shall not use Licensing Software in the following ways:
                        </p>

                        <p className='mt-3'>
                            Any behavior in violation of laws and regulations, public order, and good morals, or harmful for public interests.
                            Rent, lend, copy, amend, link, reproduce, compile, issue, publish, and set up mirror sites for licensing software without authorization. Based on Licensing Software, developing any Licensing Software related derivative products, works, services, plugins, compatibility, interconnections, etc.
                            Log in or use Licensing Software through any third-party compatible software system, which is not developed, authorized, or approved by AllInOneTrack, or use plugins that are not developed, authorized, or approved by AllInOneTrack on Licensing Software.
                            Delete any copyright-related information or contents on Licensing Software or the other copies thereof. Modify, delete, or intentionally avoid technique security measures in the application, which are set up by AllInOneTrack for intellectual property protection purposes.
                            Without AllInOneTrack's prior written consent, rent, lend, or sub-license the Licensing Software to a 3rd party, or simultaneously using multiple versions of Licensing Software, or transferring separately, after the license is granted.
                            Copy, disassemble or modify Licensing Software, in whole or in part, or create any derivative works.
                        </p>
                        <p className='mt-3'>
                            Engage in any activities that are threatening for Net info Security, in any form, including damaging or destroying Licensing Software or making it inoperable or overloaded, or disturbing any third party's normal use; accessing others' computer systems and deleting, modifying, or adding Memory Info without permission; intentionally spreading malicious programs or viruses, or any other disruptive behavior which might damage or disturb normal Net info Services.
                            Use Licensing Software to publish, transmit, spread, or store any substances which are infringing others' rights of intellectual property, rights of trade secret, etc., and/or engage in illegal or criminal activities, such as frauds, theft of others' accounts, or funds, etc.
                            Add, delete, or change the function or operational result by modifying or counterfeiting any running commands, data, data packages, or disseminate or run any application software compiled for purposes mentioned above through the internet.
                            Any other use of Licensing Software in illegal manners, for illegal purposes, or inconsistent with This Agreement.
                        </p>

                        <p className='mt-3'>You understand and agree that:</p>
                        <p className='mt-3'>
                            AllInOneTrack is authorized to affirm, in its discretion, whether you have breached the alleged terms, and based on that, proceed to suspend, or terminate your license or take some other restrictive measures adopted hereby, without any prior consent or notice.
                        </p>
                        <p className='mt-3'>
                            If you post information in suspected of any violation of laws, people's legitimate rights or This Agreement while using Licensing Software, AllInOneTrack is authorized to remove that information without any prior notice, unless you have provided evidence to proof its legality.

                        </p>
                        <p className='mt-3'>
                            You shall be independently liable on your own behalf for the damages of any third parties caused by your breach of the alleged specifications. In addition, AllInOneTrack is released from any losses and/or increased costs and you shall indemnify AllInOneTrack for any incurred compensations for third party losses in case that AllInOneTrack makes compensations in advance.

                        </p>
                        <p className='mt-3'>
                            If your alleged violation of relevant laws and/or provisions of This Agreement make AllInOneTrack suffer from any losses, compensation for a third party, and/or penalty from the Administrative Department, you shall indemnify AllInOneTrack for losses and incurred legal costs because of that, including without limitation, reasonable attorney fees, costs of investigation and evidence collection.

                        </p>
                        <p className='mt-3'>
                            You agree that any breach of This Agreement will make AllInOneTrack suffer from irreparable losses, not merely money. In addition to AllInOneTrack’s available compensation and other remedial measures, you agree that AllInOneTrack is entitled to injunctions, to prevent actual, threatening, or continuous violation of This Agreement.
                        </p>
                        <p className='mt-3'>
                            Termination and breach of contract
                        </p>
                        <p className='mt-3'>
                            In the event that you commit any breach of terms of This Agreement, AllInOneTrack shall have right to unilaterally terminate This Agreement without prior notice.

                        </p>
                        <p className='mt-3'>
                            Upon occurrence of termination, you shall stop using Licensing Software and destroy all copies of Licensing Software.

                        </p>
                        <p className='mt-3'>
                            You shall be liable for any losses and actual damages suffered by AllInOneTrack or other users, because of your breach of This Agreement.

                        </p>
                        <p className='mt-3'>
                            Governing law and dispute resolution

                        </p>
                        <p className='mt-3'>
                            The validity, interpretation, modification, execution, and dispute settlement shall be governed and construed by the laws and regulations of the Republic of Armenia. In the event that there are no relevant regulations, reference shall be made to the relevant provisions of local laws and/or general international practices.

                        </p>
                        <p className='mt-3'>
                            Any disputes arising from This Agreement shall be settled by both Parties through friendly negotiations. In the event that such disputes cannot be settled through negotiations, such disputes shall be submitted to the court of competent jurisdiction where AllInOneTrack is located.

                        </p>

                        <p className='mt-3'>
                            If any of the provisions of This Agreement is held to be invalid by any court of competent jurisdiction, then such invalidity shall not affect any of the remaining provisions of This Agreement. And such remaining provisions shall be implemented by you and AllInOneTrack.

                        </p>
                        <p className='mt-3'>
                            Others
                        </p>
                        <p className='mt-3'>
                            AllInOneTrack reserve right to transfer the rights and obligations herein to a third party without your consent.
                        </p>
                        <p className='mt-3'>
                            This Agreement constitutes the entire agreement between the parties related to granting license of Licensing Software and supersedes all prior negotiations.
                        </p>

                    </p>
                </div>
            </div>
        </div>
    )
}
