
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { MenuIcon, SearchIcon, ShoppingCartIcon, UserIcon, XIcon, ArrowSmRightIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon } from '@heroicons/react/solid'

import { useParams } from "react-router-dom";
import { Tracking } from '../Models/Tracking';
import { useHistory } from "react-router-dom";

const currencies = ['CAD', 'USD', 'AUD', 'EUR', 'GBP']
const navigation = {
    categories: [
        {
            name: 'Women',
            featured: [
                { name: 'Sleep', href: '#' },
                { name: 'Swimwear', href: '#' },
                { name: 'Underwear', href: '#' },
            ],
            collection: [
                { name: 'Everything', href: '#' },
                { name: 'Core', href: '#' },
                { name: 'New Arrivals', href: '#' },
                { name: 'Sale', href: '#' },
            ],
            categories: [
                { name: 'Basic Tees', href: '#' },
                { name: 'Artwork Tees', href: '#' },
                { name: 'Bottoms', href: '#' },
                { name: 'Underwear', href: '#' },
                { name: 'Accessories', href: '#' },
            ],
            brands: [
                { name: 'Full Nelson', href: '#' },
                { name: 'My Way', href: '#' },
                { name: 'Re-Arranged', href: '#' },
                { name: 'Counterfeit', href: '#' },
                { name: 'Significant Other', href: '#' },
            ],
        },
        {
            name: 'Men',
            featured: [
                { name: 'Casual', href: '#' },
                { name: 'Boxers', href: '#' },
                { name: 'Outdoor', href: '#' },
            ],
            collection: [
                { name: 'Everything', href: '#' },
                { name: 'Core', href: '#' },
                { name: 'New Arrivals', href: '#' },
                { name: 'Sale', href: '#' },
            ],
            categories: [
                { name: 'Artwork Tees', href: '#' },
                { name: 'Pants', href: '#' },
                { name: 'Accessories', href: '#' },
                { name: 'Boxers', href: '#' },
                { name: 'Basic Tees', href: '#' },
            ],
            brands: [
                { name: 'Significant Other', href: '#' },
                { name: 'My Way', href: '#' },
                { name: 'Counterfeit', href: '#' },
                { name: 'Re-Arranged', href: '#' },
                { name: 'Full Nelson', href: '#' },
            ],
        },
    ],
    pages: [
        { name: 'Company', href: '#' },
        { name: 'Stores', href: '#' },
    ],
}


const timeline = [
    {
        id: 1,
        content: '',
        target: 'Label Created',
        href: '#',
        date: 'Dec 22',
        datetime: '2021-12-22',
        iconBackground: 'bg-blue-500',
    },
    {
        id: 2,
        content: '',
        target: 'On its way',
        href: '#',
        date: 'Dec 23',
        datetime: '2021-12-23',
        icon: ArrowSmRightIcon,
        iconBackground: 'bg-blue-500',
    },
    {
        id: 3,
        content: '',
        target: 'Out for delivery',
        href: '#',
        date: '',
        datetime: '',
        iconBackground: 'bg-gray-500',
    },
    {
        id: 4,
        content: '',
        target: 'Delivered',
        href: '#',
        date: '',
        datetime: '',
        iconBackground: 'bg-gray-500',
    },
]
const products = [
    {
        id: 1,
        name: 'Nomad Tumbler',
        description:
            'This durable and portable insulated tumbler will keep your beverage at the perfect temperature during your next adventure.',
        href: '#',
        price: '35.00',
        status: 'Preparing to ship',
        step: 1,
        date: 'March 24, 2021',
        datetime: '2021-03-24',
        address: ['Floyd Miles', '7363 Cynthia Pass', 'Toronto, ON N3Y 4H8'],
        email: 'f•••@example.com',
        phone: '1•••••••••40',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-03-product-01.jpg',
        imageAlt: 'Insulated bottle with white base and black snap lid.',
    },
    {
        id: 2,
        name: 'Minimalist Wristwatch',
        description: 'This contemporary wristwatch has a clean, minimalist look and high quality components.',
        href: '#',
        price: '149.00',
        status: 'Shipped',
        step: 0,
        date: 'March 23, 2021',
        datetime: '2021-03-23',
        address: ['Floyd Miles', '7363 Cynthia Pass', 'Toronto, ON N3Y 4H8'],
        email: 'f•••@example.com',
        phone: '1•••••••••40',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/confirmation-page-03-product-02.jpg',
        imageAlt:
            'Arm modeling wristwatch with black leather band, white watch face, thin watch hands, and fine time markings.',
    },
    // More products...
]
const footerNavigation = {
    account: [
        { name: 'Manage Account', href: '#' },
        { name: 'Saved Items', href: '#' },
    ],
    service: [
        { name: 'Shipping & Returns', href: '#' },
        { name: 'Warranty', href: '#' },
        { name: 'FAQ', href: '#' },
        { name: 'Find a store', href: '#' },
        { name: 'Get in touch', href: '#' },
    ],
    company: [
        { name: 'Who we are', href: '#' },
        { name: 'Press', href: '#' },
        { name: 'Careers', href: '#' },
        { name: 'Terms & Conditions', href: '#' },
        { name: 'Privacy', href: '#' },
    ],
    connect: [
        { name: 'Instagram', href: '#' },
        { name: 'Pinterest', href: '#' },
        { name: 'Twitter', href: '#' },
    ],
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const [open, setOpen] = useState(false)
    const [trackingNumber, setTrackingNumber] = useState<string>("");



    const history = useHistory();

    const handleTrack = useCallback(() => {
        history.push(`/track/${trackingNumber}`);
    }, [trackingNumber])


    const handleInputChange = (e: any) => {
        setTrackingNumber(e.target.value)
    }

    return (<>
        <div className=" px-4 space-y-2 sm:px-0 sm:items-baseline sm:justify-between sm:space-y-0">
            <div className="sm:items-baseline sm:space-x-4">
                <h1 className="mb-5 text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Track your package</h1>
            </div>
        </div>
        {/* Products */}
        <section aria-labelledby=" px-4  products-heading" className="mt-6">
            <input onChange={handleInputChange} value={trackingNumber} className="shadow appearance-none border border-red-500 rounded w-full py-4 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="search" type="text" placeholder="Enter your tracking number" />
            <button onClick={handleTrack} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Track
            </button>
        </section>
    </>
    )
}

